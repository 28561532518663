import { Injectable } from '@angular/core';
import { BehaviorSubject, distinctUntilChanged, map, Observable } from 'rxjs';
import { cloneDeep, isEqual } from 'lodash-es';
import { Course, User } from '../types/types';
import { isProgramManager } from '../shared/user-utils';
import { FakeClock } from '../utils/fake-clock';

@Injectable({
  providedIn: 'root'
})
export class StateService {
  private readonly subject = new BehaviorSubject<State>({
    user: undefined,
    course: undefined,
    preview: undefined,
    draft: undefined,
    pauseTimer: false,
    params: {}
  });

  constructor() {
    (window as any).state = this;
  }

  setState(state: Partial<State>): void {
    const newState = {
      ...this.subject.value,
      ...cloneDeep(state)
    };

    this.subject.next(newState);
  }

  select<T>(selector: (state: State) => T): Observable<T> {
    return this.subject.pipe(
      map(state => selector(state))
    );
  }

  observe<T extends keyof State>(...keys: T[]): Observable< {[K in T]: State[K]}> {
    return this.subject
      .pipe(
        map(state => keys.reduce((result, key) => {
          result[key] = state[key];
          return result;
          }, {} as any)
        ),
        distinctUntilChanged((previous, current) =>
          keys.every(key => isEqual(previous[key], current[key]))
        ),
    );
  }
}

export interface State {
  // undefined: app is initializing
  // null: user is not authenticated
  user: User | null | undefined;
  preview: boolean | undefined;
  draft: boolean | undefined;
  course: Course | undefined;
  pauseTimer: boolean;
  params: { clock?: FakeClock; startYearUTC?: number; selectionMethod?: string };
}
